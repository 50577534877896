
import { formatDateNormal } from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { UrgentDeliveryManagementController } from "@/app/ui/controllers/UrgentDeliveryManagementController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { ApiStateData } from "@/domain/entities/Api";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import {
  UrgentDeliveryManagementDetailData,
  UrgentDeliveryManagementPODData,
  UrgentDeliveryManagementPODListRequest,
  UrgentDeliveryManagementPODListRequestDownload
} from "@/domain/entities/UrgentDeliveryManagement";
import { Options, Vue } from "vue-class-component";
import Detail from "../detail.vue";

@Options({
  components: {
    Detail,
  },
})
export default class UrgentPOD extends Vue {
  controller = UrgentDeliveryManagementController;
  parameters = {
    search: "",
    onSort: false,
    flag: new OptionsClass()
  };
  modalDetail = false;
  apiDetailData = new ApiStateData();
  urgentDeliveryManagementDetailData = new UrgentDeliveryManagementDetailData();

  columns = [
    {
      name: "No",
      styleHead: "w-12 text-left",
      render: (item: UrgentDeliveryManagementPODData, i: number) =>
        `<span class="${
          this.getStyleByFlag(item.pdFlag).class
        } font-normal overflow-ellipisis text-left text-black-lp-300 whitespace-nowrap">${i +
          1 +
          this.pagination.limit * (this.pagination.page - 1)}</span>`
    },
    {
      name: "No.STT",
      styleHead: "w-44 text-left ",
      render: (item: UrgentDeliveryManagementPODData) =>
        `<div class="overflow-ellipsis text-left flex flex-col gap-1 text-sm text-black-lp-100">
          <div>
            ${item.pdSTTNo}
          </div>
          ${
            item.pdFlag
              ? `<div class="flex flex-row gap-1">
            <img src="${require(`@/app/ui/assets/svg/${
              this.getStyleByFlag(item.pdFlag).icon
            }`)}" class="text-red-lp-100" width="16" />
            <div class="font-semibold ${
              this.getStyleByFlag(item.pdFlag).textClass
            }">${item.pdFlag}</div>
          </div>`
              : ""
          }
        </div>`
    },
    {
      name: "Layanan Pengiriman",
      styleHead: "w-36 text-left",
      render: (item: UrgentDeliveryManagementPODData) =>
        `<div class="overflow-ellipsis text-left font-normal text-sm">
          ${item.sttDeliveryType} 
        </div>`
    },
    {
      name: "Alasan",
      styleHead: "text-left w-60",
      render: (item: UrgentDeliveryManagementPODData) =>
        `<div class="overflow-ellipsis text-left font-normal text-sm">${item.pdReason}</div>`
    },
    {
      name: "Batas akhir POD",
      styleHead: "text-left w-40",
      render: (item: UrgentDeliveryManagementPODData) =>
        `<div class="overflow-ellipsis text-left font-normal text-sm">${this.getDeadlineFormatDate(
          item.pdDeadlineReturn
        )}</div>`
    },
    {
      name: "Status terakhir",
      styleHead: "text-left w-40",
      render: (item: UrgentDeliveryManagementPODData) =>
        `<div class="overflow-ellipsis text-left font-normal text-sm">${item.sttLastReturnId}</div>`
    },
    {
      name: "Detail penerima",
      styleHead: "text-left w-64",
      render: (item: UrgentDeliveryManagementPODData) =>
        `<div class="overflow-ellipsis text-left flex flex-col font-normal text-sm gap-1">
          <div class="flex flex-row gap-1 flex-wrap">
            <div>${item.sttRecipientName}</div>
            <div class="flex flex-row items-center">
              <img src="${require("@/app/ui/assets/svg/whatsapp.svg")}" width="17" height="17" />
              <a class="text-blue-lp-500 target-blank" target="_blank" href="https://wa.me/${
                item.sttRecipientPhone
              }">${item.sttRecipientPhone}</a>
            </div>
          </div>
          <div class="text-gray-lp-600">${item.sttDestinationDistrictName.toLocaleLowerCase()}</div>
        </div>`
    },
    {
      name: "Jumlah percobaan DEX",
      styleHead: "text-left w-32",
      render: (item: UrgentDeliveryManagementPODData) =>
        `<div class="overflow-ellipsis text-left font-normal text-sm">${item.deliveryDEXAttempt}</div>`
    }
  ];

  get pagination() {
    return this.controller.paginationData;
  }

  mounted(): void {
    this.fetchList(true);
  }

  filterOptions = [
    new OptionsClass({
      name: "Kiriman Penting",
      value: "Kiriman Penting"
    }),
    new OptionsClass({
      name: "SLA hari ini",
      value: "SLA hari ini"
    }),
    new OptionsClass({
      name: "Lewat batas SLA",
      value: "Lewat batas SLA"
    }),
    new OptionsClass({
      name: "Delivery Ulang",
      value: "Delivery Ulang"
    })
  ];

  fetchList(reset = false): void {
    if (reset) {
      this.controller.paginationData.page = 1;
    }

    this.controller.getPODList(
      new UrgentDeliveryManagementPODListRequest({
        sortBy: this.parameters.onSort ? "asc" : "desc",
        sttNo: this.parameters.search,
        page: this.controller.paginationData.page,
        limit: this.controller.paginationData.limit,
        flag: this.parameters.flag.value
      })
    );
  }

  onSearch(value: string) {
    if (!value.length || value.length >= 3) {
      this.parameters.search = value;
      this.fetchList(true);
    }
  }

  onFilter(data: OptionsClass): void {
    Object.assign(this.parameters, { flag: data });
    this.fetchList(true);
  }

  onClear(): void {
    this.parameters.search = "";
    this.fetchList(true);
  }

  getStyleByFlag(flag: string): {
    class: string;
    icon: string;
    textClass: string;
  } {
    if (flag.toLowerCase().includes("kiriman penting")) {
      return {
        class: "urgent-delivery",
        icon: "plane-booking-active.svg",
        textClass: "text-red-lp-100"
      };
    }
    if (flag.toLowerCase().includes("sla hari ini")) {
      return {
        class: "today-sla",
        icon: "clock-orange.svg",
        textClass: "text-yellow-lp-100"
      };
    }
    if (flag.toLocaleLowerCase().includes("lewat batas sla")) {
      return {
        class: "overdue-sla",
        icon: "clock-warning.svg",
        textClass: "text-red-lp-100"
      };
    } else {
      return {
        class: "delivery-proccess",
        icon: "delivery-proccess.svg",
        textClass: "text-gray-lp-600"
      };
    }
  }

  getDeadlineFormatDate(date: string): string {
    const currentDate = new Date().toISOString();

    if (formatDateNormal(currentDate) === formatDateNormal(date)) {
      return "Hari Ini";
    } else return formatDateNormal(date, "DD MMMM YYYY");
  }

  get sortButtonStyle() {
    return this.parameters.onSort
      ? {
          textColor: "red-lp-300",
          customClass: "w-40 mr-4 bg-red-lp-1300 btn-active",
          color: "red-lp-300"
        }
      : {
          textColor: "gray-lp-500",
          customClass: "w-40 mr-4",
          color: ""
        };
  }

  get emptyStateData(): {
    headerMessage: string;
    message: string;
    icon: string
  } {
    if (this.parameters.search.length || this.parameters.onSort) {
      return {
        headerMessage: "Filter tidak ditemukan",
        message: "Cek dan atur ulang filter yang Anda cari.",
        icon: "noData_filter"
      }
    } else {
      return {
        headerMessage: "Belum ada kiriman penting",
        message: "Yuk! Mulai masukkan/scan nomor STT pada menu STI DEST.",
        icon: "noData_empty"
      }
    }
  }

  async onDownload() {
    await this.controller.downloadUrgentPODList(
      new UrgentDeliveryManagementPODListRequestDownload({
        sortBy: this.parameters.onSort ? "asc" : "desc",
        sttNo: this.parameters.search,
      })
    );
  }

  async goToDetail(item: UrgentDeliveryManagementPODData) {
    await this.getDetailData(item.pdId);
  }

  closeDetail() {
    this.modalDetail = false;
  }

  async getDetailData(id: number) {
    MainAppController.showLoading();
    try {
      this.urgentDeliveryManagementDetailData = await UrgentDeliveryManagementController.getPriorityDeliveryManagementDetail(
        id
      );
      this.modalDetail = true;
      this.apiDetailData.errorType = "";
    } catch (error) {
      MainAppController.closeLoading();
      MainAppController.showErrorMessage(
        new ErrorMessageEntities({
          type: "client-error",
          title: "Gagal mendapatkan detail!",
          onClose: () => {
            MainAppController.closeErrorMessage();
          },
        })
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
