
import { ellipsisString, formatDate } from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { UrgentDeliveryManagementController } from "@/app/ui/controllers/UrgentDeliveryManagementController";
import { ApiStateData } from "@/domain/entities/Api";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import {
  UrgentDeliveryManagementDetailData,
  UrgentDeliveryManagementHALData,
  UrgentDeliveryManagementHALListRequest,
  UrgentDeliveryManagementHALListRequestDownload
} from "@/domain/entities/UrgentDeliveryManagement";
import { Options, Vue } from "vue-class-component";
import Detail from "../detail.vue";

@Options({
  components: {
    Detail,
  },
})
export default class UrgentHAL extends Vue {
  controller = UrgentDeliveryManagementController;
  parameters = {
    search: "",
    onSort: false,
  };
  modalDetail = false;
  apiDetailData = new ApiStateData();
  urgentDeliveryManagementDetailData = new UrgentDeliveryManagementDetailData();

  columns = [
    {
      name: "No",
      styleHead: "text-left head-no",
      render: (item: UrgentDeliveryManagementHALData, i: number) =>
        `<span class="${
          this.getStyleByFlag(item.pdFlag).class
        } font-normal text-black-lp-300 whitespace-nowrap">${i +
          1 +
          this.pagination.limit * (this.pagination.page - 1)}</span>`
    },
    {
      name: "No.STT",
      styleHead: "text-left head-no-stt",
      render: (item: UrgentDeliveryManagementHALData) =>
        `<div class="flex flex-col gap-1 text-sm text-black-lp-100">
          <div>
            ${item.pdSTTNo}
          </div>
        </div>`
    },
    {
      name: "Alasan",
      styleHead: "text-left head-reason",
      render: (item: UrgentDeliveryManagementHALData) =>
        `<div class="font-normal text-sm text-black-lp-100">${item.pdReason}</div>`
    },
    {
      name: "Kota Asal",
      styleHead: "text-left head-origin",
      render: (item: UrgentDeliveryManagementHALData) =>
        `<div class="font-normal text-sm text-black-lp-100">${item.sttOriginCityId} - ${item.sttOriginCityName}</div>`
    },
    {
      name: "Status terakhir",
      styleHead: "text-left head-last-status",
      render: (item: UrgentDeliveryManagementHALData) =>
        `<div class="font-normal text-sm text-black-lp-100">${item.sttLastStatusId}</div>`
    },
    {
      name: "Waktu permintaan",
      styleHead: "text-left head-request-time",
      render: (item: UrgentDeliveryManagementHALData) =>
        `<div class="font-normal text-sm text-black-lp-100">${this.getCreatedAtFormatDate(item.pdCreatedAt)}</div>
        <div class="capitalize text-gray-lp-500 break-all">${ellipsisString(item.createdBy, 20)}</div>`
    },
  ];

  mounted(): void {
    this.fetchList(true);
  }

  fetchList(reset = false): void {
    if (reset) {
      this.controller.paginationData.page = 1;
    }

    this.controller.getHALList(
      new UrgentDeliveryManagementHALListRequest({
        sortBy: this.parameters.onSort ? "asc" : "desc",
        sttNo: this.parameters.search,
        page: this.controller.paginationData.page,
        limit: this.controller.paginationData.limit
      })
    );
  }

  onSearch(value:string) {
    if (!value.length || value.length >= 3) {
      this.parameters.search = value;
      this.fetchList(true);
    }
  }

  clearSearch() {
    this.parameters.search = "";
    this.fetchList(true);
  } 

  getStyleByFlag(
    flag: string
  ): {
    class: string;
    icon: string;
    textClass: string;
  } {
    if (flag.toLowerCase().includes("hari ini")) {
      return {
        class: "today",
        icon: "clock-normal-red.svg",
        textClass: "text-red-lp-100"
      };
    }
    if (flag.toLowerCase().includes("lewat")) {
      return {
        class: "overdue",
        icon: "clock-exclamation.svg",
        textClass: "text-yellow-lp-100"
      };
    }
    else {
      return {
        class: "normal",
        icon: "clock-normal.svg",
        textClass: "text-gray-lp-500"
      };
    }
  }

  getCreatedAtFormatDate(date: string): string {
    return formatDate(date);
  }

  get sortButtonStyle() {
    return this.parameters.onSort
      ? {
          textColor: "red-lp-300",
          customClass: "w-full mr-4 bg-red-lp-1300 btn-active",
          color: "red-lp-300"
        }
      : {
          textColor: "gray-lp-500",
          customClass: "w-full mr-4",
          color: ""
        };
  }

  get emptyStateData(): {
    headerMessage: string;
    message: string;
    icon: string
  } {
    if (this.parameters.search.length) {
      return {
        headerMessage: "Filter tidak ditemukan",
        message: "Cek dan atur ulang filter yang Anda cari.",
        icon: "noData_filter"
      }
    } else {
      return {
        headerMessage: "Belum ada kiriman penting",
        message: "Yuk! Mulai masukkan/scan nomor STT pada menu STI DEST.",
        icon: "noData_empty"
      }
    }
  }

  get pagination() {
    return this.controller.paginationData;
  }

  async onDownload() {
    await this.controller.downloadUrgentHALList(
      new UrgentDeliveryManagementHALListRequestDownload({
        sortBy: this.parameters.onSort ? "asc" : "desc",
        sttNo: this.parameters.search,
      })
    );
  }

  async goToDetail(item: UrgentDeliveryManagementHALData) {
    await this.getDetailData(item.pdId);
  }

  closeDetail() {
    this.modalDetail = false;
  }

  async getDetailData(id: number) {
    MainAppController.showLoading();
    try {
      this.urgentDeliveryManagementDetailData = await UrgentDeliveryManagementController.getPriorityDeliveryManagementDetail(
        id
      );
      this.modalDetail = true;
      this.apiDetailData.errorType = "";
    } catch (error) {
      MainAppController.closeLoading();
      MainAppController.showErrorMessage(
        new ErrorMessageEntities({
          type: "client-error",
          title: "Gagal mendapatkan detail!",
          onClose: () => {
            MainAppController.closeErrorMessage();
          },
        })
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
