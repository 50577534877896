
import { UrgentDeliveryManagementDetailData } from "@/domain/entities/UrgentDeliveryManagement";
import { PropType } from "vue";
import { Vue, prop } from "vue-class-component";

class Props {
  data = prop<UrgentDeliveryManagementDetailData>({
    required: true,
    default: new UrgentDeliveryManagementDetailData()
  })
  close = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function,
  });
}
export default class Detail extends Vue.with(Props) {
  photoPreview = false;
  photo = "";
  onPreview(photo: string) {
    this.photo = photo;
    this.photoPreview = true;
  }

  get photoPreviews() {
    return this.data.rpdProofPhoto.map((item: any) => {
      return {
        image: item,
        url: item
      }
    });
  }

  isValidLink(link: string) {
    const urlPattern = /^https?:\/\//i;
    return urlPattern.test(link) ? link : `http://${link}`;
  }
}
