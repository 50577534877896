import {
  UrgentDeliveryManagementPODData,
  UrgentDeliveryManagementPODList,
  UrgentDeliveryManagementPODListRequest,
  UrgentDeliveryManagementRTSData,
  UrgentDeliveryManagementRTSList,
  UrgentDeliveryManagementRTSListRequest,
  UrgentDeliveryManagementHALData,
  UrgentDeliveryManagementHALList,
  UrgentDeliveryManagementHALListRequest,
  UrgentDeliveryManagementPODListRequestDownload,
  UrgentDeliveryManagementHALListRequestDownload,
  UrgentDeliveryManagementRTSListRequestDownload,
} from "@/domain/entities/UrgentDeliveryManagement";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { UrgentDeliveryManagementPresenter } from "../presenters/UrgentDeliveryManagementPresenter";
import { Pagination } from "@/domain/entities/Pagination";
import { MainAppController } from "./MainAppController";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import { AccountController } from "./AccountController";
import { parsingErrorResponse, storeDatatoLocalStorage } from "@/app/infrastructures/misc/Utils";

export interface UrgentDeliveryManagementState {
  isLoading: boolean;
  PODList: UrgentDeliveryManagementPODData[];
  RTSList: UrgentDeliveryManagementRTSData[];
  HALList: UrgentDeliveryManagementHALData[];
  paginationData: Pagination;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "urget-delivery-management"
})
class UrgentDeliveryManagementStore extends VuexModule
  implements UrgentDeliveryManagementState {
  public isLoading = false;
  public PODList: UrgentDeliveryManagementPODData[] = [];
  public RTSList: UrgentDeliveryManagementRTSData[] = [];
  public HALList: UrgentDeliveryManagementHALData[] = [];
  public paginationData = new Pagination(1, 20, 100);
  public sttInfo: number = JSON.parse(
    localStorage.getItem("sttInfo") || "{}"
  );

  get isAccountForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  @Action
  public getPODList(params: UrgentDeliveryManagementPODListRequest): void {
    MainAppController.closeErrorMessage();
    this.setLoading(true);

    const presenter = container.resolve(UrgentDeliveryManagementPresenter);
    presenter
      .getPODList(params)
      .then(res => {
        this.setPODListData(res);
      })
      .catch(err => {
        const message = err.response.data.message;
        MainAppController.showErrorMessage(
          typeof message === "string"
            ? new ErrorMessageEntities({
                title: this.isAccountForeign
                  ? "Failed to Get POD List"
                  : "Gagal Mengambil Data POD",
                message: err.response.data.message
              })
            : parsingErrorResponse(err)
        );
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public downloadUrgentPODList(params: UrgentDeliveryManagementPODListRequestDownload): void {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();

    const presenter = container.resolve(UrgentDeliveryManagementPresenter);
    presenter
      .downloadUrgentPODList(params)
      .catch((err) => {
        MainAppController.showErrorMessage(
          new ErrorMessageEntities({
            title: this.isAccountForeign ? "Failed to Download POD List" : "Gagal Mengunduh Data POD",
            message: err.response.data.message
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public getRTSList(params: UrgentDeliveryManagementRTSListRequest): void {
    MainAppController.closeErrorMessage();
    this.setLoading(true);

    const presenter = container.resolve(UrgentDeliveryManagementPresenter);
    presenter
      .getRTSList(params)
      .then(res => {
        this.setRTSListData(res);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          new ErrorMessageEntities({
            title: this.isAccountForeign ? "Failed to Get RTS List" : "Gagal Mengambil Data RTS",
            message: err.response.data.message
          })
        );
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public downloadUrgentRTSList(params: UrgentDeliveryManagementRTSListRequestDownload): void {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();

    const presenter = container.resolve(UrgentDeliveryManagementPresenter);
    presenter
      .downloadUrgentRTSList(params)
      .catch((err) => {
        MainAppController.showErrorMessage(
          new ErrorMessageEntities({
            title: this.isAccountForeign ? "Failed to Download RTS List" : "Gagal Mengunduh Data RTS",
            message: err.response.data.message
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public getHALList(params: UrgentDeliveryManagementHALListRequest): void {
    MainAppController.closeErrorMessage();
    this.setLoading(true);

    const presenter = container.resolve(UrgentDeliveryManagementPresenter);
    presenter
      .getHALList(params)
      .then(res => {
        this.setHALListData(res);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          new ErrorMessageEntities({
            title: this.isAccountForeign ? "Failed to Get HAL List" : "Gagal Mengambil Data HAL",
            message: err.response.data.message
          })
        );
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public downloadUrgentHALList(params: UrgentDeliveryManagementHALListRequestDownload): void {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();

    const presenter = container.resolve(UrgentDeliveryManagementPresenter);
    presenter
      .downloadUrgentHALList(params)
      .catch((err) => {
        MainAppController.showErrorMessage(
          new ErrorMessageEntities({
            title: this.isAccountForeign ? "Failed to Download HAL List" : "Gagal Mengunduh Data HAL",
            message: err.response.data.message
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public getUrgentDeliveryManagementTakenRecipient(params: UrgentDeliveryManagementHALListRequest) {
    const presenter = container.resolve(UrgentDeliveryManagementPresenter);
    return presenter.getUrgentDeliveryManagementTakenRecipient(params);
  }

  @Action
  public getPriorityDeliveryManagementDetail(id: number) {
    const presenter = container.resolve(UrgentDeliveryManagementPresenter);
    return presenter.getPriorityDeliveryManagementDetail(id);
  }


  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool;
  }

  @Mutation
  public setSttInfo(sttInfo: any) {
    this.sttInfo = sttInfo;
    storeDatatoLocalStorage("sttInfo", sttInfo);
  }

  @Mutation
  private setPODListData(data: UrgentDeliveryManagementPODList): void {
    this.PODList = data.data;
    this.paginationData = data.pagination;
  }

  @Mutation
  private setRTSListData(data: UrgentDeliveryManagementRTSList): void {
    this.RTSList = data.data;
    this.paginationData = data.pagination;
  }

  @Mutation
  private setHALListData(data: UrgentDeliveryManagementHALList): void {
    this.HALList = data.data;
    this.paginationData = data.pagination;
  }
}

export const UrgentDeliveryManagementController = getModule(
  UrgentDeliveryManagementStore
);
